angular.module('fingerink')
    .factory('payTPVService', ['$http', 'session', function ($http, session) {
        var sdo = {

            getTarjetas: function () {
                return $http({
                    method: 'GET',
                    url: 'paytpv/cards',
                    crossDomain: true
                });
            },
            
            
            updateTarjetaDefault: function (id) {
                return $http({
                    method: 'PUT',
                    url: 'paytpv/cards/' + id,
                    crossDomain: true
                });
            },

            deleteTarjeta: function (tarjetaId) {
                return $http({
                    method: 'DELETE',
                    url: 'paytpv/cards/' + tarjetaId,
                    crossDomain: true
                });
            },
            
            
            getIframeUrlTarjeta: function (id) {
                return $http({
                    method: 'GET',
                    url: 'paytpv/iframeAddCard/' + id,
                    crossDomain: true
                });
            },
            
            isTarjetaLoaded: function (id) {
                return $http({
                    method: 'GET',
                    url: 'paytpv/isCardAdded/' + id,
                    crossDomain: true
                });
            }


        };
        return sdo;
}]);
